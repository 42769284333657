import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Button } from "@chakra-ui/react";

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "redirect") {
            instance.loginRedirect(loginRequest)
                .catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <Button onClick={() => handleLogin("redirect")} bg="#7688a5">Sign in</Button>
    );
}