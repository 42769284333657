import React, { useEffect, useState } from "react";
import './App.css';

import { Tabs, Tab, TabList, TabPanels, TabPanel } from '@chakra-ui/react';
import { PageLayout } from "./components/PageLayout";
import { useAccount, useMsal } from "@azure/msal-react";
import MachineLookup from "./MachineLookup";
import LogViewer from "./LogViewer";
import Admin from "./Admin";

function App() {
  const { instance } = useMsal();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const accountInfo = useAccount();
  const accountUsername = accountInfo ? accountInfo.username : '';

  if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const foundAccountForTenant = instance.getAllAccounts().find((accountInfo) => accountInfo.tenantId === '1724f78d-4a6a-4750-9182-a96dbb31d6c1');
    if (foundAccountForTenant) {
      instance.setActiveAccount(instance.getAllAccounts()[0]);
    }
  }

  const checkIfAdmin = (account) => {
    if (!account || !account.idTokenClaims) {
      return false;
    }
    const claims = account.idTokenClaims;
    const roles = claims.roles ?? [];

    return roles.includes('MachineInfo.Admin');
  };

  const checkIfCanViewLogs = (account) => {
    if (!account || !account.idTokenClaims) {
      return false;
    }
    const claims = account.idTokenClaims;
    const roles = claims.roles ?? [];

    return roles.includes('MachineInfo.ViewLogs');
  };

  const isAdmin = checkIfAdmin(accountInfo);
  const canViewLogs = checkIfCanViewLogs(accountInfo);

  return (
    <PageLayout username={accountUsername}>
      <Tabs
        isLazy
        orientation="vertical"
        className="main-body"
        defaultIndex={currentTabIndex}
        flexGrow={1}
        onChange={(index) => setCurrentTabIndex(index)}>
        <TabList>
          <Tab>SD Generator</Tab>
          {canViewLogs &&
            <Tab>Logs</Tab>
          }
          {isAdmin &&
            <Tab>Admin</Tab>
          }
        </TabList>
        <TabPanels>
          <TabPanel>
            <MachineLookup username={accountUsername} isAdmin={isAdmin} />
          </TabPanel>
          <TabPanel>
            <LogViewer />
          </TabPanel>
          <TabPanel>
            <Admin />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
}

export default App;