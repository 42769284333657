export let baseURL = process.env.REACT_APP_APIURL

export const accessHeader = (token, headers) => {
  let headerValues = {
    headers: {
      "Authorization": "Bearer " + token
    }
  };

  if (headers) {
    headerValues.headers = { ...headers, ...headerValues.headers };
  }
  console.log(headerValues);
  return headerValues;
}
