import { baseURL, accessHeader } from "./config";

const baseAPIURL = baseURL + "logs/";

export const LogApi = {
  getLatest: async function (accessToken) {
    const fetchURL = baseAPIURL;
    const result = await fetch(fetchURL, accessHeader(accessToken)).then(res => res.json());

    return result;
  },
  getFile: async function (accessToken, datestamp, filename) {
    // datestamp format: [yyyy-mm-dd-seconds]
    const fetchURL = baseAPIURL + datestamp + "/" + filename;
    const result = await fetch(fetchURL, accessHeader(accessToken)).then(res => res.text());
    console.log(result);
    return result;
  },
  getConfig: async function (accessToken, serial) {
    const fetchURL = baseAPIURL + serial + "/config";
    const result = await fetch(fetchURL, accessHeader(accessToken)).then(res => res.json());

    return result;
  },
  getByRefcode: async function (accessToken, refcode) {
    const fetchURL = baseAPIURL + refcode;
    const result = await fetch(fetchURL, accessHeader(accessToken)).then(res => res.json());

    return result;
  },
}