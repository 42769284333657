import React from "react";

import LogLookup from "./LogLookup";
import RecentLogs from "./RecentLogs";

import { Box, Heading } from '@chakra-ui/react';

function LogViewer(props) {

  return (
    <Box maxW={700} margin='auto' className="LogViewer">
      <Box m={20}>
        <Heading as='h1' mb={20}><span>Epilog Laser</span></Heading>
        <LogLookup />
        <RecentLogs />
      </Box>
    </Box>
  );
}

export default LogViewer;