import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react'
import { Heading, Text, Link } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

function FlashHelp() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Link mb={2} color="blue.500" onClick={onOpen}><InfoOutlineIcon mr={1} />How to flash images.</Link>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>How to flash images with Balena Etcher</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading size="md" pt={3} pb={1}>Download and install balena Etcher</Heading>
            <Text>If you do not already have balena Etcher installed, you'll need to download and install it on your system.</Text>
            <Text>You can download it <Link color="blue.500" href="https://etcher.balena.io/#download-etcher" isExternal>here.</Link></Text>
            <Heading size="md" pt={3} pb={1}>Insert an SD card</Heading>
            <Text>Insert the SD card you want the image written to into your reader.</Text>
            <Heading size="md" pt={3} pb={1}>Download the image</Heading>
            <Text>Download and load the file using the download link and select it with the "Flash from file" button in Etcher.</Text>
            <Heading size="md" pt={3} pb={1}>Etcher- Select target</Heading>
            <Text>Select the previously inserted SD card as your target.</Text>
            <Text fontSize="sm" color="tomato">Make sure you have the correct target selected because this process will completely overwrite everything on the target.</Text>
            <Heading size="md" pt={3} pb={1}>Etcher- Flash!</Heading>
            <Text>Click the "Flash!" button in Etcher to start writing the image to the SD card.</Text>
            <Heading size="md" pt={3} pb={1}>Finished</Heading>
            <Text>Once the writing and verification steps are complete the SD card is ready to be used in a machine.</Text>
            <ModalFooter />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default FlashHelp;