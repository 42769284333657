import { useEffect, useState } from 'react';
import { Progress } from "@chakra-ui/react";

function ProgressTimer(props) {
  const [progress, setProgress] = useState(0);
  const startTime = props.startTime;
  // const [startTime, setStartTime] = useState();
  const estimatedDuration = 300;

  const calcSecondsLeft = () => {
    console.log('calcSecondsLeft');
    const now = Date.now() / 1000;
    const endTime = startTime + estimatedDuration;
    const secsDiff = Math.max((endTime - now), 0);
    const percentLeft = 100 / (estimatedDuration / secsDiff);
    setProgress(100 - percentLeft);
  };

  //******************************************************************************
  // Effects
  //******************************************************************************
  useEffect(() => {
    if (!startTime) return;
    console.log('startTime: ' + startTime);
    const tickInterval = setInterval(calcSecondsLeft, 1000);

    return () => clearInterval(tickInterval);
  }, [startTime]);

  return (
    <>
      <p>Generating Image</p>
      <Progress value={progress} />
    </>
  );
}

export default ProgressTimer;