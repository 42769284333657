import React from "react";

import FirmwareManager from "./FirmwareManager";

import { Box, Heading } from '@chakra-ui/react';

function Admin(props) {

  return (
    <Box maxW={700} margin='auto' className="LogViewer">
      <Box m={20}>
        <Heading as='h1' mb={20}><span>Epilog Laser</span></Heading>
        <FirmwareManager />
      </Box>
    </Box>
  );
}

export default Admin;