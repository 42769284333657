import { useState, useEffect } from 'react';

import LogCard from './LogCard';

import { Heading, Box, HStack } from '@chakra-ui/react';
import { Input, Button } from '@chakra-ui/react';
import { LogApi } from './api/log';
import useAPIAccess from './hooks/useAPIAccess';

function LogLookup(props) {
  const username = props.username;
  // Add state to store serial number,
  const [refcode, setRefcode] = useState('');
  const [lookupResults, setLookupResults] = useState({ searchTerm: "", results: [] });
  const [statusMessage, setStatusMessage] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const { callWithAccessToken } = useAPIAccess();

  //******************************************************************************
  // Event Handlers
  //******************************************************************************
  // Query API when serial entered
  const handleLookupClick = () => {
    setIsWaiting(true);

    callWithAccessToken((accessToken) => {
      LogApi.getByRefcode(accessToken, refcode)
        .then((results) => {
          if (results.length < 1) {
            setStatusMessage('Refcode not found in the last 30 days.');
          } else {
            setLookupResults({ searchTerm: refcode, results: results });
            setStatusMessage('');
          }

          setIsWaiting(false);
        })
        .catch((er) => {
          setIsWaiting(false);
          setStatusMessage('Error performing search.');
        })
    });
  };

  const resultList = lookupResults.results.map((r) => <LogCard log={r} />);

  //******************************************************************************
  // Effects
  //******************************************************************************

  return (
    <Box mx='auto' mb={5} className="LogLookup">
      <Heading pb={5} size='md'>Diagnostic Logs</Heading>
      <HStack pb={5}>
        <Input
          value={refcode}
          onChange={(e) => setRefcode(e.target.value)}
          placeholder='Refcode' />
        {isWaiting === false &&
          <Button px={10} onClick={handleLookupClick}>Search</Button>
        }
      </HStack>
      {statusMessage &&
        <Box>
          {statusMessage}
        </Box>
      }
      {lookupResults.searchTerm.length > 0 &&
        <p>Results for "{lookupResults.searchTerm}"</p>
      }
      {resultList}
    </Box>
  );
}

export default LogLookup;
