import { baseURL, accessHeader } from "./config";

const baseAPIURL = baseURL + "products/";

export const ProductApi = {
  get: async function (accessToken, serial) {
    const fetchURL = baseAPIURL + serial;
    const result = await fetch(fetchURL, accessHeader(accessToken))
      .then(res => {
        console.log(res);
        if (res.status === 404) {
          return { serial: '', base_firmware: '' };
        }
        return res.json();
      });

    return result;
  },
  getConfig: async function (accessToken, serial) {
    const fetchURL = baseAPIURL + serial + "/config";
    const result = await fetch(fetchURL, accessHeader(accessToken)).then(res => res.json());

    return result;
  },
}