import { useState, useEffect } from 'react';

import { Heading, Box, HStack } from '@chakra-ui/react';
import { Input, Button, Select } from '@chakra-ui/react';
import MachineCard from './MachineCard';
import ImageStatus from './ImageStatus';
import { ImageApi } from './api/image';
import { ProductApi } from './api/product';
import { FirmwareApi } from './api/firmware';
import RecentImages from './RecentImages';
import FlashHelp from './FlashHelp';
import useAPIAccess from './hooks/useAPIAccess';

function MachineLookup(props) {
  const username = props.username;
  const isAdmin = props.isAdmin;
  // Add state to store serial number,
  const [serial, setSerial] = useState('');
  const [product, setProduct] = useState({ serial: '0-0', base_firmware: '0.0.0.0' });
  const [firmwares, setFirmwares] = useState([]);
  const [selectedFirmware, setSelectedFirmware] = useState();
  const [receiptCode, setReceiptCode] = useState({ code: null, serial: '', fwv: '', error: null });
  const [statusMessage, setStatusMessage] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const { callWithAccessToken } = useAPIAccess();

  // Query API when serial entered
  const handleLookupClick = () => {
    callWithAccessToken((accessToken) => {
      ProductApi.get(accessToken, serial)
        .then(res => {
          if (res === undefined) return;

          if (res.serial.length < 1) {
            setStatusMessage('Serial not found.');
            setProduct({ serial: '0-0', base_firmware: '0.0.0.0' });
            setReceiptCode({ code: null, serial: '', fwv: '', error: null });
          } else {
            setProduct(res);
            setStatusMessage(null);
            setReceiptCode({ code: null, serial: '', fwv: '', error: null });
          }
        },
          (err) => {
            console.log(err);
          });
    });
  };

  // Get Firmware list
  const getFirmwareList = () => {
    callWithAccessToken((accessToken) => {
      FirmwareApi.get(accessToken)
        .then((result) => {
          setFirmwares(result);
        });
    });
  }

  const requestImage = () => {
    callWithAccessToken((accessToken) => {
      ImageApi.create(accessToken, serial, selectedFirmware, username)
        .then((r) => {
          console.log(r);
          setReceiptCode({ code: r.receiptCode, serial: serial, fmv: selectedFirmware, error: null });
          setIsWaiting(true);
        })
        .catch(e => {
          console.log(e);
          setReceiptCode({ code: null, serial: '', fmv: '', error: e.message });
          setIsWaiting(false);
        });
    });
  };

  // Generate Firmware options
  // Filter list by version number

  // firmware comparison function
  // returns true if test version is lte/eq to base, false otherwise
  const compareVersions = (base, test) => {
    const baseComponents = base.split('.');
    const testComponents = test.split('.');
    let baseGteOEq = false;

    for (let i = 0; i < baseComponents.length; i++) {
      const element = parseInt(baseComponents[i]);
      const testElement = parseInt(testComponents[i]);
      if (element == testElement) {
        baseGteOEq = true;
      } else if (testElement > element) {
        baseGteOEq = true;
        break;
      } else {
        baseGteOEq = false;
        break;
      }
    }

    return baseGteOEq;
  };

  const filteredFirmwareList = () => {
    const isPro48 = product.serial.search(/^16/) > -1;
    const isG100 = product.sku === 'GALVO0_TWO_AXIS';
    const baseFirmware = product.base_firmware || '0.0.0.0';

    let filteredFirmwares = firmwares.filter((el) => compareVersions(baseFirmware, el)).sort().reverse();

    if (!isAdmin) {
      // Beta firmwares have "_beta..." in the firmware name, these are only available to admins.
      filteredFirmwares = filteredFirmwares.filter(el => !el.includes("_"));
    }

    if (isPro48) {
      // Pro48/16000 machines don't support:
      // 1.0.3.0, 1.0.3.1, 1.0.3.2, 1.0.3.3, 1.0.3.4, 1.0.4.0, 1.0.7.1, 1.0.8.0
      const excludedFW = ['1.0.3.0', '1.0.3.1', '1.0.3.2', '1.0.3.3', '1.0.3.4', '1.0.4.0', '1.0.7.1'];
      filteredFirmwares = filteredFirmwares.filter((el) => !excludedFW.includes(el));
    }

    // 4-21-2023 Latest update is that 1.0.8.0 is good to go, so no longer restricting it for
    // any machines.

    // if (!isG100) {
    //   // 1.0.8.0 is unstable so we are restricting generation for flatbeds, but it is needed for the Galvo.
    //   const excludedFW = ['1.0.8.0'];
    //   filteredFirmwares = filteredFirmwares.filter((el) => !excludedFW.includes(el));
    // }

    return filteredFirmwares;
  }

  const FirmwareOptions = () => {
    let filteredFirmwares = filteredFirmwareList();

    return filteredFirmwares.map((fw, i) => {
      if (i === 0) return (<option key={fw} value={fw}>{fw}</option>);
      return (
        <option key={fw} value={fw}>{fw}</option>
      );
    });
  };

  // Set form state while waiting on image generation. This should prevent users from 
  // requesting multiple downloads and overwritting their previous receipt code.
  const setFormWaitingState = () => {

  }

  //******************************************************************************
  // Event Handlers
  //******************************************************************************

  const handleFirmwareSelect = (e) => {
    console.log(e.target.value);
    setSelectedFirmware(e.target.value);
  };

  const handleGetImageClick = () => {
    requestImage();
  }

  //******************************************************************************
  // Effects
  //******************************************************************************

  useEffect(() => {
    getFirmwareList();
  }, []);

  useEffect(() => {
    let filteredFirmwares = filteredFirmwareList();

    if (!selectedFirmware) {
      console.log('setting selected firmware');
      setSelectedFirmware(filteredFirmwares[0]);
    }
  }, [product]);

  return (
    <Box maxW={700} margin='auto' className="App">
        <Heading as='h1' m={20}><span>Epilog Laser</span></Heading>
        <Box m={20}>
          <Heading pb={5} size='md'>SD Image Generator</Heading>
          <HStack pb={5}>
            <Input
              value={serial}
              onChange={(e) => setSerial(e.target.value)}
              placeholder='Serial #' />
            {isWaiting === false &&
              <Button px={10} onClick={handleLookupClick}>Lookup</Button>
            }
          </HStack>
          {statusMessage &&
            <Box>
              {statusMessage}
            </Box>
          }
          {product.serial != '0-0' &&
            <Box pb={5}>
              <MachineCard machine={product} />
              {isWaiting === false &&
                <HStack>
                  <Select
                    placeholder='Select Firmware'
                    value={selectedFirmware}
                    onChange={(e) => handleFirmwareSelect(e)}>
                    <FirmwareOptions />
                  </Select>
                <Button px={10} onClick={handleGetImageClick} isDisabled={false}>Get Image</Button>
                </HStack>
              }
            </Box>
          }
          {receiptCode.error || receiptCode.code &&
            <ImageStatus receipt={receiptCode} />
          }
        <FlashHelp />
          <RecentImages username={username} />
        </Box>
    </Box>
  );
}

export default MachineLookup;
