import React, { useEffect, useState } from "react";

import LogCard from "./LogCard";

import { Box, VStack } from '@chakra-ui/react';
import { Card, CardHeader, CardBody, CardFooter, Heading, Badge, Link } from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';

import { LogApi } from "./api/log";
import useAPIAccess from './hooks/useAPIAccess';

function RecentLogs(props) {
  const [latestLogs, setLatestLogs] = useState([]);
  const [isBusyLoading, setIsBusyLoading] = useState(false);
  const { callWithAccessToken } = useAPIAccess();

  const getFile = (log, filename) => {
    const datestamp = log.datestamp;
    callWithAccessToken((accessToken) => {
      LogApi.getFile(accessToken, datestamp, filename)
        .then((r) => {
          window.open(r, '_blank').focus();
        });
    })
  };

  const LogList = latestLogs.map((l) => <LogCard log={l} key={l.refcode} />);

  const refreshLogs = () => {
    console.log('gettings logs');
    setIsBusyLoading(true);

    callWithAccessToken((accessToken) => {
      LogApi.getLatest(accessToken)
        .then((r) => {
          setIsBusyLoading(false);
          setLatestLogs(r);
        });
    });
  };

  useEffect(() => {
    refreshLogs();
  }, []);

  return (
    <Box className="LogViewer">
      <Heading pb={5} size='md'>Recent Logs</Heading>
      <IconButton isLoading={isBusyLoading} mb='10px' aria-label='Refresh List' onClick={refreshLogs} icon={<RepeatIcon />} />
      <VStack align='stretch'>
        {LogList}
      </VStack>
    </Box>
  );
}

export default RecentLogs;