import { useEffect, useState } from 'react';
import { ImageApi } from './api/image';
import useAPIAccess from './hooks/useAPIAccess';
import { Card, CardHeader, CardBody, CardFooter, Button } from '@chakra-ui/react';
import ProgressTimer from './ProgressTimer';

function ImageStatus(props) {
  const [imageGenStatus, setImageGenStatus] = useState(false);
  const [resourceLocation, setResourceLocation] = useState(null);
  const [startTime, setStartTime] = useState();
  const [generationError, setGenerationError] = useState(null);
  const receiptCode = props.receipt.code;
  const firmware = props.receipt.fmv;
  const serial = props.receipt.serial;
  const error = props.receipt.error;
  const { callWithAccessToken } = useAPIAccess();

  const checkImageStatus = () => {
    callWithAccessToken((accessToken) => {
      ImageApi.get(accessToken, receiptCode)
        .then((r) => {
          const isReady = r.ready;
          setImageGenStatus(isReady);
          if (isReady) {
            const path = process.env.REACT_APP_APIURL + "static/" + r.resource;
            setResourceLocation(path);
          }
        })
        .catch((err) => {
          setGenerationError(err);
          setResourceLocation(null);
        });
    });
  };

  const handleTimerStart = () => {
    setStartTime(Date.now() / 1000);
  };

  useEffect(() => {
    const hasCode = receiptCode != null;
    // Dont execute if you don't have a code or if the imageGenStatus is true (ready)
    console.log(hasCode);
    if (!hasCode || imageGenStatus || generationError !== null) {
      return;
    }

    console.log('setting interval');
    const statusPoller = setInterval(() => {
      checkImageStatus();
    }, 5000);

    return () => clearInterval(statusPoller);
  });

  useEffect(() => {
    if (!receiptCode) return;
    setGenerationError(null);
    setStartTime(Date.now() / 1000);
  }, [receiptCode]);

  // useEffect(() => {
  //   const countdownTimer = setInterval(() => {
  //     calcSecondsLeft();
  //   }, 6000);

  //   return () => clearInterval(countdownTimer);
  // });

  const isInProgress = resourceLocation === null && generationError === null;
  const downloadFilename = serial + '_' + firmware + '.zip'; 
  const etcherLink = 'https://efp.balena.io/open-image-url?imageUrl=' + resourceLocation;

  return (
    <Card>
      <CardBody>
        {isInProgress &&
          <ProgressTimer startTime={startTime} />
        }
        {generationError != null &&
          <>{generationError.message}</>
        }
        {resourceLocation != null &&
          <><a className='download' href={resourceLocation} download={downloadFilename}>Download Image</a></>
        }
      </CardBody>
    </Card>
  );
}

export default ImageStatus;