import React from "react";
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { Box, Text } from "@chakra-ui/react";
import { Card, CardHeader, CardBody, CardFooter, Heading } from '@chakra-ui/react'

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <Box bg='#004C75' color='white' textAlign='right' p="10px">
        {isAuthenticated ? <SignOutButton username={props.username} /> : <Box h='40px'></Box>}
      </Box>
      <UnauthenticatedTemplate>
        <Box p="50px 20px">
          <Card maxW='xs' m='auto' align='center'>
            <CardHeader>
              <Heading size='md'>Access Machine Support</Heading>
            </CardHeader>
            <CardBody>
              <Text>Sign in with your Epilog Laser account</Text>
            </CardBody>
            <CardFooter color='#fff'>
              <SignInButton />
            </CardFooter>
          </Card>
        </Box>
      </UnauthenticatedTemplate>
      <MsalAuthenticationTemplate>
        {props.children}
      </MsalAuthenticationTemplate>
    </>
  );
};