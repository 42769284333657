import { baseURL, accessHeader } from "./config";

export const ImageApi = {
  create: async function (accessToken, serial, fwVersion, userid) {
    const fetchURL = baseURL + "image";
    const payload = {
      "serial": serial,
      "fwVersion": fwVersion,
      "userid": userid
    };

    console.log(payload);
    const result = await fetch(fetchURL, {
      ...accessHeader(accessToken, { 'Content-Type': 'application/json' }),
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (res.status === 503) throw new Error('Working on another job, try again in ~5 min.');
        return res;
      })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) throw new Error(res.error);
        return res;
      })
      .catch(e => {
        throw e;
      });

    return result;
  },
  get: async function (accessToken, receiptCode) {
    const fetchURL = baseURL + "image/" + receiptCode;
    const imageResult = await fetch(fetchURL, accessHeader(accessToken))
      .then((res) => {
        if (res.status === 404) throw new Error('Image generation failed');
        return res;
      })
      .then(res => res.json());

    return imageResult;
  },
  getRecent: async function (accessToken) {
    const fetchURL = baseURL + "image/recent";
    const imageResult = await fetch(fetchURL, accessHeader(accessToken)).then(res => res.json());

    return imageResult;
  }
}