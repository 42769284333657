import { useState } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Button, useDisclosure } from '@chakra-ui/react'
import { ProductApi } from './api/product';
import { JSONViewer } from './components/JSONViewer';
import useAPIAccess from './hooks/useAPIAccess';

function MachineCard(props) {
  const machine = props.machine;
  const [machineConfig, setMachineConfig] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { callWithAccessToken } = useAPIAccess();

  let buildDate = new Date(machine.build_date);
  buildDate = (buildDate.getMonth() + 1) + '-' + buildDate.getDate() + '-' + buildDate.getFullYear();

  const handleGetInfo = () => {
    callWithAccessToken((accessToken) => {
      ProductApi.getConfig(accessToken, machine.serial).then(res => {
        setMachineConfig(res);
        onOpen();
      });
    });
  };

  return (
    <>
      <Card mb={5} maxW='xs'>
        <CardHeader>
          <p>{machine.name}</p>
        </CardHeader>
        <CardBody>
          <p>{machine.serial}</p>
          <p>Build Date: {buildDate}</p>
          <p>Base Firmware: {machine.base_firmware}</p>
          <Button onClick={handleGetInfo}>View Config</Button>
        </CardBody>
      </Card>
      <JSONViewer isOpen={isOpen} onClose={onClose} content={machineConfig} />
    </>
  );
}

export default MachineCard;