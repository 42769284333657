import React, { useEffect, useState } from "react";
import { ImageApi } from './api/image';
import { Heading, Text, List, ListItem } from '@chakra-ui/react';
import { HStack, Spacer } from "@chakra-ui/react";
import { Tag } from "@chakra-ui/react";
import useApiAccess from './hooks/useAPIAccess';

function RecentImages(props) {
  const username = props.username;
  const [availableImages, setAvailableImages] = useState([]);
  const { callWithAccessToken } = useApiAccess();

  const checkImageStatus = () => {
    callWithAccessToken((accessToken) => {
      ImageApi.getRecent(accessToken)
        .then((r) => {
          setAvailableImages(r.toReversed());
        });
    });
  };

  const imageList = availableImages.map(i => {
    const path = process.env.REACT_APP_APIURL + "static/" + i.receiptcode + '.img.zip';
    const downloadFilename = i.serial + '_' + i.fw + '.zip';
    const etcherLink = 'https://efp.balena.io/open-image-url?imageUrl=' + path;
    return (<ListItem borderBottom='1px solid #dedede' pb='5px' key={i.receiptcode}><HStack><Text>{i.serial}</Text><Tag variant='outline' colorScheme='blue'>{i.fw}</Tag><Spacer /><a className='download' href={path} download={downloadFilename}>Download</a></HStack></ListItem>);
  });

  //******************************************************************************
  // Effects
  //******************************************************************************

  useEffect(() => {
    checkImageStatus();
  }, [username]);

  return (
    <>
      <Heading mt={5} pb={5} size='md'>Recent</Heading>
      <Text pb={5}>Download previously generated images. Images are cleared after 24 hours.</Text>
      <List spacing={3}>
        {imageList}
      </List>
    </>
  );
}

export default RecentImages;