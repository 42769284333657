import { Card, CardBody, Heading, Badge, Link, Flex, Spacer, Text, Divider } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import { CalendarIcon, DownloadIcon, EmailIcon, SunIcon } from '@chakra-ui/icons';

import { LogApi } from "./api/log";
import useAPIAccess from './hooks/useAPIAccess';

function LogCard(props) {
  const datestamp = props.log.datestamp;
  const refcode = props.log.refcode;
  const serial = props.log.serial || "Serial n/a";
  const email = props.log.email || "Email n/a";
  const files = props.log.files;
  const { callWithAccessToken } = useAPIAccess();

  const uuidPattern = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/;
  const dataLessTime = datestamp.split("-").slice(1, 3).join("/");

  const fileList = files.map((f) => {
    const truncatedFilename = f.replace(uuidPattern, refcode + "... ");
    return (
      <li key={f}><Flex><Text>{truncatedFilename}</Text><Spacer /><Button rightIcon={<DownloadIcon />} size="sm" colorScheme="blue" onClick={() => getFile(f)}>Download</Button></Flex></li>
    );
  });

  const getFile = (filename) => {
    callWithAccessToken((accessToken) => {
      LogApi.getFile(accessToken, datestamp, filename)
        .then((r) => {
          window.open(r, '_blank').focus();
        });
    });
  };

  return (
    <Card key={refcode} className='LogCard'>
      <CardBody>
        <Heading size='sm' pb='20px'>{refcode} </Heading>
        <p><CalendarIcon /> {dataLessTime}</p>
        <p><SunIcon /> {serial}</p>
        <p><EmailIcon /> {email}</p>
        <Divider py="5px" />
        <Heading size='xs' py='5px'>Files</Heading>
        <ul>{fileList}</ul>
      </CardBody>
    </Card>
  );
}

export default LogCard;