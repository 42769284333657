import { baseURL, accessHeader } from "./config";

const baseAPIURL = baseURL + "firmware/";

export const FirmwareApi = {
  get: async function (accessToken) {
    const fetchURL = baseAPIURL;
    const result = await fetch(fetchURL, accessHeader(accessToken)).then(res => res.json());

    return result;
  },
  post: async function (accessToken, file) {
    const fetchURL = baseAPIURL;
    var data = new FormData();
    data.append('ffile', file);

    const result = await fetch(fetchURL, { ...accessHeader(accessToken), method: 'POST', body: data });

    return result;
  },
}