import { useState, useRef } from 'react';

import { Box } from '@chakra-ui/react';
import { Input, Button } from '@chakra-ui/react';
import { CircularProgress } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react'

import { FirmwareApi } from './api/firmware';
import useAPIAccess from './hooks/useAPIAccess';

function FirmwareManager(props) {
  const [isBusy, setIsBusy] = useState(false);
  const fileInputRef = useRef();
  const toast = useToast();
  const { callWithAccessToken } = useAPIAccess();

  const handleUpload = (e) => {
    e.preventDefault();
    if (!fileInputRef.current.value) return;

    const formFile = e.target[0].files[0];
    setIsBusy(true);
    callWithAccessToken((accessToken) => {
      FirmwareApi.post(accessToken, formFile)
        .then((r) => {
          finishUpload();
          if (r.status === 200) {
            toast({ title: "File Uploaded", position: "top", status: 'success' });
          } else if (r.status === 409) {
            toast({ title: "Can't upload duplicate.", position: "top", status: 'error' });
          } else if (r.status === 415) {
            toast({ title: "Unsupported file format.", position: "top", status: 'error' });
          }

        })
        .catch((err) => {
          console.log(err);
          toast({ title: "Couldn't upload file", position: "top", status: 'error' });
          finishUpload();
        });
    });

  };

  const finishUpload = () => {
    fileInputRef.current.value = "";
    setIsBusy(false);
  };

  return (
    <Box mx='auto' mb={5} className="FirmwareManager">
      {isBusy &&
        <CircularProgress isIndeterminate />
      }
      <form onSubmit={handleUpload}>
        <Input type='file' name='ffile' ref={fileInputRef} />
        <Button type='submit' isDisabled={isBusy} px={10}>Upload</Button>
      </form>
    </Box>
  );
}

export default FirmwareManager;