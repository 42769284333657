export const msalConfig = {
  auth: {
    clientId: "f9a91ce0-8641-4143-b9ed-5c9d3d129e7b",
    authority: "https://elciam.ciamlogin.com/1724f78d-4a6a-4750-9182-a96dbb31d6c1", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://machineinfo.epiloglaser.com",
    clientCapabilities: ['CP1'], // this lets the resource owner know that this client is capable of handling claims challenge.
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  graphMe: {
    endpoint: 'https://graph.microsoft.com/v1.0/me',
    scopes: ['User.Read'],
  },
  graphPhoto: {
    endpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
    scopes: ['User.Read'],
  },
  graphContacts: {
    endpoint: 'https://graph.microsoft.com/v1.0/me/contacts',
    scopes: ['Contacts.Read'],
  },
};