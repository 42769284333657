import { useState, useEffect } from "react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';

const useAPIAccess = () => {
  const { instance, inProgress, accounts } = useMsal();

  const retrieveAccessToken = (cb) => {
    const activeAccount = instance.getActiveAccount();
    if (!activeAccount) {
      console.log('no account to get access token');
      return;
    }
    console.log(instance.getActiveAccount());
    if (inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        scopes: ["api://f9a91ce0-8641-4143-b9ed-5c9d3d129e7b/access_via_approle_assignments"],
        account: instance.getActiveAccount(),
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          console.log(accessToken);
          if (cb) {
            cb(accessToken);
          }
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                let accessToken = accessTokenResponse.accessToken;
                if (cb) {
                  cb(accessToken);
                }
              })
              .catch(function (error) {
                // Acquire token interactive failure
                console.log(error);
              });
          }
          console.log(error);
        });
    }
  };

  useEffect(() => {
  }, [instance, accounts, inProgress]);

  return {
    callWithAccessToken: retrieveAccessToken
  }
}

export default useAPIAccess;