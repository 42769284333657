import React from "react";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

export const JSONViewer = (props) => {
  const machineConfigs = props.content;
  const keys = Object.keys(props.content);

  const SectionForData = ({ data }) => {
    const keys = Object.keys(data);

    return (
      <>
        {keys.map((k, index) => {
          const keyVal = data[k];
          const keyContent = keyVal.toString();

          return (<div className="section" key={index}>
            <h4>{k}</h4>
            <p>{keyContent}</p>
          </div>);
        })}
      </>
    );
  };

  const ConfigTabs = ({ data }) => {
    return (
      <Tabs>
        <TabList>
          {data.map((tab, index) => (
            <Tab key={index}>{tab.name}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {data.map((tab, index) => {
            let content = tab.data;
            if (tab.name.search('json') < 0) {
              // content = JSON.stringify(tab.data);
              content = iniToObject(content);
            }
            return (
              <TabPanel p={4} key={index}>
                <SectionForData data={content} />
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    )
  }

  const iniToObject = (data) => {
    const whitespacePattern = /\s+/;
    const whitespaceSplit = data.trim().split(whitespacePattern);
    const equalSplit = whitespaceSplit.map(w => w.split('='));
    let iniObj = {};
    equalSplit.forEach(e => {
      iniObj[e[0]] = e[1];
    });
    return iniObj;
  };

  return (
    <div>
      <Modal size='xl' scrollBehavior='inside' isOpen={props.isOpen} onClose={props.onClose} >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Machine Config</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="json-viewer">
            <ConfigTabs data={machineConfigs} />
          </ModalBody>
        </ModalContent>
      </Modal >
    </div>
  );
};