import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";

// import { protectedResources } from '../authConfig';
// import useGraphWithMsal from '../hooks/useGraphWithMsal';

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = (props) => {
    const { instance } = useMsal();
    // const [graphData, setGraphData] = useState(null);

    // const { error, execute, result } = useGraphWithMsal({
    //     scopes: protectedResources.graphPhoto.scopes,
    // }, protectedResources.graphPhoto.endpoint);

    // useEffect(() => {
    //     if (!!graphData) {
    //         return;
    //     }

    //     if (!graphData) {
    //         execute(protectedResources.graphPhoto.endpoint).then((data) => {
    //             setGraphData(data);
    //             console.log(data);
    //         });
    //     }

    // }, [graphData, execute, result]);

    const handleLogout = (logoutType) => {
        if (logoutType === "redirect") {
           instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    return (
        <>
            <Box display="inline" pr="10px">{props.username}</Box>
            <Button onClick={() => handleLogout("redirect")} bg="#7688a5">Sign out</Button>
        </>
    );
}